//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "ServiceCard",

  props: {
    service: {
      type: Object,
      default: () => {
        return {}
      }
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    getCurrencyUser: function () {
      return this.$store.getters['user/user'];
    },
    getCurrency() {
      return this.$store.getters["currency/currentCurrency"];
    },
  },

  methods: {
    onBuyNumber: function () {
      this.$emit("onBuyNumber", this.service)
    }
  }
}
